import React, { ReactElement } from "react"
import { ViewApplicationTab, ViewApplicationTabProps } from "."
import { formatDate, formatDateTime, formatTime } from "@ap-frontend/utility"
import T from "@mui/material/Typography"
import SEO from "../../../../components/SEO"
import { PageProps } from "gatsby"
import { BasePageContext } from "../../../../interfaces/PageContext"
import useSubmissionAttempts, {
  SubmissionAttempt,
} from "../../../../hooks/useSubmissionAttempts"
import DescriptionList from "../../../../components/DescriptionList"
import { Timestamp } from "firebase/firestore"
import { Card, CardContent } from "../../../../components/Card"
import { useUser } from "../../../../providers/UserProvider"
import Button from "../../../../components/design-system/Button/Button"
import { Box, CircularProgress } from "@mui/material"

const SubmissionAttemptStatus = ({
  status,
}: {
  status: SubmissionAttempt["status"]
}) => {
  const formattedStatus = (
    status.charAt(0).toUpperCase() + status.slice(1)
  ).replace(/-/g, " ")

  return (
    <T
      variant="body2"
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        color:
          status === "complete"
            ? "success.main"
            : status === "in-progress"
            ? "primary.main"
            : status === "error"
            ? "error.main"
            : // Not started or skipped
              "grey.700",
      }}
    >
      {status === "in-progress" && (
        <CircularProgress size="1rem" sx={{ mr: 1 }} />
      )}
      <span>{formattedStatus}</span>
    </T>
  )
}

const SubmissionAttemptCard = ({
  attempt,
  no,
}: {
  attempt: SubmissionAttempt
  no: number
}) => {
  return (
    <Card sx={{ mb: 3 }}>
      <CardContent sx={{ p: 3 }}>
        <DescriptionList
          md={6}
          lg={3}
          sx={{ mb: -1, mt: 0 }}
          condensed
          items={[
            ["Attempt", "#" + no],
            [
              "Status",
              <SubmissionAttemptStatus
                key={attempt.id + "-status"}
                status={attempt.status}
              />,
            ],
            ["Date", formatDate(attempt.start.toDate())],
            ["Started", formatTime(attempt.start.toDate())],
            ["Finished", attempt.end ? formatTime(attempt.end.toDate()) : "-"],
            [
              "Submission ID",
              <code key={attempt.id + "-id"}>{attempt.id}</code>,
            ],
          ]}
        />
      </CardContent>
    </Card>
  )
}

export const ViewApplicationSubmissionTab: React.FC<
  ViewApplicationTabProps
> = ({ activity }) => {
  const [attempts, createSubmissionAttempt] = useSubmissionAttempts()
  const { admin } = useUser()

  const latestAttempt = attempts?.find(attempt => attempt.latest)

  return (
    <>
      <T variant="h2" component="h2">
        Submission
      </T>
      <T variant="h3" component="h3" sx={{ mb: 2, mt: 4 }}>
        Applicant submission activity
      </T>
      <DescriptionList
        md={6}
        lg={5}
        items={[
          [
            "Submitted by applicant",
            activity.submissionTimestamp ? "Yes" : "No",
          ],
          [
            "Submitted on",
            activity.submissionTimestamp
              ? formatDateTime(
                  (activity.submissionTimestamp as Timestamp).toDate()
                )
              : "-",
          ],
        ]}
      />
      <T variant="h3" component="h3" sx={{ mb: 2, mt: 4 }}>
        Submission attempts
      </T>
      {attempts && attempts.length === 0 && (
        <T variant="body1">
          There are no submission attempts for this application.
        </T>
      )}
      {latestAttempt &&
        latestAttempt.status !== "complete" &&
        admin.roles?.retrySubmission && (
          <Box sx={{ mb: 2, alignItems: "center", display: "flex" }}>
            <Button
              disabled={latestAttempt.status !== "error"}
              onClick={() => createSubmissionAttempt()}
            >
              Retry submission
            </Button>
          </Box>
        )}
      {attempts &&
        attempts.length > 0 &&
        attempts.map((attempt, index) => (
          <SubmissionAttemptCard
            key={attempt.id}
            no={attempts.length - index}
            attempt={attempt}
          />
        ))}
    </>
  )
}

const ViewApplicationSubmissionPage = ({
  params,
  pageContext,
}: PageProps<never, BasePageContext>): ReactElement => {
  const applicationNumber = params.id

  return (
    <>
      <SEO title={`Application #${applicationNumber}`} />
      <ViewApplicationTab
        applicationNumber={applicationNumber}
        TabComponent={ViewApplicationSubmissionTab}
        questions={pageContext.questions}
      />
    </>
  )
}

export default ViewApplicationSubmissionPage
