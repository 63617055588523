import { useEffect, useState } from "react"
import { useUser } from "../providers/UserProvider"
import { useFirebase } from "../providers/FirebaseProvider"
export { PaymentStatus, PaymentToken } from "@ap-frontend/types"
import {
  collection,
  query as firestoreQuery,
  orderBy,
  onSnapshot,
  Timestamp,
  QueryDocumentSnapshot,
  DocumentData,
  addDoc,
} from "firebase/firestore"
import { SubmissionDocument } from "@ap-frontend/types"

export interface SubmissionAttempt extends SubmissionDocument {
  id: string
}

export const mapSubmissionDocumentToAttempt = (
  document: QueryDocumentSnapshot<DocumentData>
): SubmissionAttempt => {
  const data = document.data() as SubmissionDocument
  return {
    ...data,
    ...(data.status === "in-progress" &&
    data.timeout.toMillis() < Timestamp.now().toMillis()
      ? { status: "error", end: data.timeout }
      : {}),
    id: document.id,
  } as SubmissionAttempt
}

/**
 * A hook that provides a convenient way to get an applicant's payment tokens.
 */
const useSubmissionAttempts = (): [
  SubmissionAttempt[] | undefined,
  () => void
] => {
  const [data, setData] = useState<SubmissionAttempt[]>([])
  const { applicationNumber, admin } = useUser()
  const { firestore } = useFirebase()

  const createNewSubmissionAttempt = async () => {
    if (!admin.roles?.retrySubmission)
      throw new Error("User does not have permission to retry submissions")

    if (!firestore) return

    await addDoc(
      collection(firestore, `applicants/${applicationNumber}/submissions`),
      {}
    )
  }

  useEffect(() => {
    if (!firestore) return
    if (!applicationNumber) return
    if (!admin.access || !admin?.roles?.audit) {
      throw new Error("User does not have permission to access audits")
    }

    const query = firestoreQuery(
      collection(firestore, `applicants/${applicationNumber}/submissions`),
      orderBy("start", "desc")
    )

    const unsubscribe = onSnapshot(query, QuerySnapshot => {
      const attempts: SubmissionAttempt[] = []
      QuerySnapshot.forEach(doc => {
        attempts.push(mapSubmissionDocumentToAttempt(doc))
      })

      attempts.map(attempt => {
        if (attempt.status === "in-progress") {
          // Check it's not timed out
          const now = Timestamp.now()
          if (attempt.timeout < Timestamp.now()) {
            attempt.status = "error"
            attempt.end = now
          }
        }

        return attempt
      })

      setData(attempts)
    })

    return () => {
      unsubscribe()
    }
  }, [firestore, admin, applicationNumber])

  return [data, createNewSubmissionAttempt]
}

export const useLatestSubmissionAttempt = (): SubmissionAttempt | undefined => {
  const [attempts] = useSubmissionAttempts()

  if (!attempts) return undefined
  return attempts.find(attempt => attempt.latest)
}

export default useSubmissionAttempts
